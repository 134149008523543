import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  Show,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceManyField,
  BooleanField,
  BooleanInput,
  ListButton,
  RefreshButton,
  TabbedShowLayout,
  Tab,
  FilterButton,
  SingleFieldList,
  DeleteWithConfirmButton,
  usePermissions,
  useRecordContext,
  ReferenceField,
  required,
  Pagination,
  Button,
  NumberField,
} from "react-admin";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DateRangeIcon from "@material-ui/icons/DateRange";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { MeetingRelationshipTypeField } from "./CustomFields";
import { AmplifyImageField } from "./AmplifyImageField";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { Logger } from "aws-amplify";
import { CustomList, ListPagination } from "./CustomList";
import { getScheduledMeetingsByTeamHtml } from "../lib/helpers";
import { AmplifyFileField } from "./AmplifyFileField";
import CreateTranscriptionAdminJobButton from "./StartTranscriptionAdminJobButton";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listTeams";
const ReactSwal = withReactContent(Swal);
const validateRequired = [required()];
const logger = new Logger("Team");

const filters = [
  <TextInput
    label="Name"
    source="name"
    resettable
    alwaysOn
  />,
];

const ListActions = (props: any) => {
  const {
    className,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton />
      <CreateButton />
    </TopToolbar>
  );
};

const CreateNewMeetingButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/meetings/create",
    }}
    label="Create new meeting"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewTeamMeetingRelationshipButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamMeetingRelationships/create",
      state: { record: { teamId: props.id as string } },
      search: `?source=${JSON.stringify({ teamId: props.id as string })}`,
    }}
    label="Add meeting"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewTeamMembershipRelationshipButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamMemberRelationships/create",
      state: { record: { teamId: props.id as string } },
      search: `?source=${JSON.stringify({ teamId: props.id as string })}`,
    }}
    label="Add member"
  >
    <ContactMailIcon />
  </CreateButton>
);

const AddNewProductButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/products/create",
      state: { record: { salesTeamId: props.id as string } },
      search: `?source=${JSON.stringify({ salesTeamId: props.id as string })}`,
    }}
    label="Add product"
  >
    <ContactMailIcon />
  </CreateButton>
);

const ShowActions = (props: ShowProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  // NOTE "data" is available in props and Matches Team to extend ShowProps
  // console.info("props", JSON.stringify(props, null, 2));
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <CreateTranscriptionAdminJobButton />
      <TeamMeetingScheduleButton />
      <AddNewTeamImageButton {...props} />
      <CreateNewMeetingButton {...props} />
      <AddNewTeamMeetingRelationshipButton {...props} />
      <AddNewTeamMembershipRelationshipButton {...props} />
      <AddNewProductButton {...props} />
      <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
    </TopToolbar>
  );
};

const AddNewTeamImageButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamImages/create",
      state: { record: { teamId: props.id as string } },
      search: `?source=${JSON.stringify({ teamId: props.id as string })}`,
    }}
    label="Add image"
  >
    <ContactMailIcon />
  </CreateButton>
);

/**
 *  Team meeting schedule
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const TeamMeetingScheduleButton = (props: ShowProps | EditProps): JSX.Element => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  return (
    <Button
      onClick={async () => {
        let timeZones: string[] = [];
        if ((Intl as any).supportedValuesOf) {
          timeZones = (Intl as any).supportedValuesOf('timeZone');
        }
        // logger.debug('timeZones', timeZones);
        let timeZone = 'America/New_York';
        const selectedInput = await ReactSwal.fire({
          title: 'Time zone',
          input: 'select',
          inputOptions: {
            'Timezone': timeZones,
          },
          inputValue: timeZones.indexOf(timeZone),
          inputPlaceholder: 'Select a time zone',
          showCancelButton: false,
          confirmButtonColor: "#056839",
          cancelButtonColor: "#39B54A",
        });
        if (selectedInput && `${selectedInput.value}`.length > 0) {
          try {
            timeZone = timeZones[parseInt(selectedInput.value)];
          } catch(err) { }
        }
        logger.debug('selected timeZone', timeZone);
        ReactSwal.fire(
          {
            title: 'Please wait...',
            icon: 'info',
            showCloseButton: true,
            timer: 1000 * 120,
            timerProgressBar: false,
            didOpen: () => {
              ReactSwal.showLoading();
            },
            confirmButtonColor: "#056839",
            cancelButtonColor: "#39B54A",
          }
        );
        getScheduledMeetingsByTeamHtml(
          `${record.id}`,
          timeZone,
        ).then(
          (scheduleHtml) => {
            try {
              // NOTE: cancel loading dialog
              ReactSwal.close();
            } catch(err: any) { }
            const scheduleUrl = URL.createObjectURL(
              new Blob([scheduleHtml], { type: "text/html" })
            );
            const scheduleWin = window.open(
                scheduleUrl,
                "schedulewin",
                `width=1024,height=768,screenX=200,screenY=200`
            );
            try {
              scheduleWin?.focus();
            } catch(err) {}
          }
        ).catch(
          (err) => {
            logger.error('getScheduledMeetingsByTeamHtml failed', err);
            Sentry.captureException(err);
            try {
              // NOTE: cancel loading dialog
              ReactSwal.close();
            } catch(err: any) { }
            ReactSwal.fire({
              title: "Error",
              text: "Schedule failed to generate!",
              icon: "warning"
            });
          }
        );
      }}
      label="Print Schedule"
    >
      <DateRangeIcon />
    </Button>
  );
}

const EditActions = (props: EditProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <TeamMeetingScheduleButton />
      <AddNewTeamImageButton {...props} />
      <CreateNewMeetingButton {...props} />
      <AddNewTeamMeetingRelationshipButton {...props} />
      <AddNewTeamMembershipRelationshipButton {...props} />
      <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
    </TopToolbar>
  );
};

export const TeamList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <TextField source="name" sortable={false} />
        <DateField source="updatedAt" showTime={true} sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const TeamShow = (props: ShowProps) => {

  const { loaded, permissions } = usePermissions();

  return (
    <Show {...props} actions={<ShowActions {...props} />}>
      <TabbedShowLayout>
        <Tab label="Team Details">
          <TextField source="id" label="ID" fullWidth />
          <TextField source="name" />
          <TextField source="description" />
          <BooleanField source="privacyEnabled" label="Enable privacy mode?" />
          <BooleanField source="privacyEnableOtherTeams" label="Enable privacy mode for other team?" />
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
        </Tab>
        <Tab label="Images">
          <ReferenceManyField
            reference="teamImages"
            target="teamId"
            label="Images"
            perPage={10}
            fullWidth
            filter={{ listTeamImagesByTeamId: {} }}
          >
            <SingleFieldList>
              <AmplifyImageField source="image" title="title" />
            </SingleFieldList>
          </ReferenceManyField>
          <AddNewTeamImageButton {...props} />
        </Tab>
        <Tab label="Meetings">
          <ReferenceManyField
            reference="teamMeetingRelationships"
            target="teamId"
            perPage={500}
            label="Meetings"
            fullWidth
            filter={{
              listTeamMeetingRelationshipsByTeamId: {}
            }}
            pagination={
              <Pagination rowsPerPageOptions={
                [300, 400, 500]
              } />
            }
          >
            <Datagrid optimized>
              <ReferenceField
                source="meetingId"
                reference="meetings"
                label="Meeting"
                link="show"
              >
                <TextField source="title" />
              </ReferenceField>
              <DateField
                source="meeting.startDateTime"
                label="Start date and time"
                showTime={true}
                sortable={false} // TODO enable sorting by start date
              />
              <DateField
                source="meeting.endDateTime"
                label="End date and time"
                showTime={true}
                sortable={false}
              />
              <MeetingRelationshipTypeField source="relationshipType" label="Seller / Buyer" />
              {(loaded && permissions.includes("superuser")) && (
                <ShowButton />
              )}
              {(loaded && permissions.includes("superuser")) && (
                <EditButton />
              )}
              <DeleteWithConfirmButton
                redirect={
                  (deleteResults) => {
                    // TODO redirect to `/teams/${data.teamId}/show/3`;
                    return `/teams`;
                  }
                }
              />
            </Datagrid>
          </ReferenceManyField>
          <AddNewTeamMeetingRelationshipButton {...props} />
        </Tab>
        <Tab label="Members">
          <ReferenceManyField
              reference="teamMemberRelationships"
              target="teamId"
              label="Members"
              perPage={50}
              fullWidth
              filter={{ listTeamMemberRelationshipsByTeamId: {} }}
              pagination={
                <Pagination rowsPerPageOptions={
                  [50, 100, 200, 300, 400]
                } />
              }
            >
              <Datagrid>
                <ReferenceField
                  source="memberProfileId"
                  reference="profiles"
                  label="User"
                  link="show"
                >
                  <TextField source="fullName" />
                </ReferenceField>
                <TextField source="relationship" />
                {(loaded && permissions.includes("superuser")) && (
                  <ShowButton />
                )}
                {(loaded && permissions.includes("superuser")) && (
                  <EditButton />
                )}
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/teams/${data.teamId}/show/3`;
                      return `/teams`;
                    }
                  }
                />
              </Datagrid>
            </ReferenceManyField>
            <AddNewTeamMembershipRelationshipButton {...props} />
        </Tab>
        <Tab label="Products">
          <ReferenceManyField
              reference="products"
              target="salesTeamId"
              perPage={2000}
              label="Products"
              fullWidth
              filter={{ listProductsBySalesTeamId: {} }}
            >
              <Datagrid>
                <TextField source="name" />
                <DateField source="createdAt" showTime={true} />
                <DateField source="updatedAt" showTime={true} />
                <ShowButton />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>
            <AddNewProductButton {...props} />
        </Tab>
        <Tab label="Transcriptions">
          <ReferenceManyField  // TODO only show if transcription is enabled
              reference="transcriptions"
              target="teamId"
              perPage={500}
              label="Transcriptions"
              fullWidth
              filter={{ listTranscriptionsByTeamId: {} }}
              pagination={
                <Pagination rowsPerPageOptions={
                  [300, 400, 500]
                } />
              }
            >
              <Datagrid>
                <TextField source="title" />
                <AmplifyFileField
                  source="result"
                  download
                  storageOptions={{ level: "public" }}
                  label="File"
                />
                <NumberField source="sizeInBytes" />
                <DateField source="startDateTime" label="Start date" showTime={true} />
                <DateField source="endDateTime" label="End date" showTime={true} />
                {(loaded && permissions.includes("superuser")) && (
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/teams/${data.teamId}/show/3`;
                      return "/teams";
                    }
                  }
                  onError={
                    (e) => {
                      try {
                        logger.warn("team transcription delete error", e);
                      } catch (_e) {}
                    }
                  }
                />
                )}
                {(loaded && permissions.includes("superuser")) && (
                <ShowButton />
                )}
              </Datagrid>
            </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const TeamEdit = (props: EditProps) => (
  <Edit
    {...props}
    actions={<EditActions {...props} />}
    undoable={false}
    mutationMode="pessimistic"
  >
    <SimpleForm
      toolbar={<DeleteWithConfirmToolbar />}
    >
      <TextInput source="id" label="ID" disabled fullWidth />
      <TextInput
        source="name"
        isRequired
        fullWidth
        validate={validateRequired}
      />
      <TextInput
        source="description"
        fullWidth
        multiline={true}
        minRows={8}
      />
      <BooleanInput
        source="privacyEnabled"
        label="Enable privacy mode?"
        defaultValue={false}
      />
      <BooleanInput
        source="privacyEnableOtherTeams"
        label="Enable privacy mode for other team?"
        defaultValue={false}
      />
    </SimpleForm>
  </Edit>
);

export const TeamCreate = (props: CreateProps) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="name"
        isRequired
        fullWidth
        validate={validateRequired}
      />
      <TextInput
        source="description"
        fullWidth
        multiline={true}
        minRows={8}
      />
      <BooleanInput
        source="privacyEnabled"
        label="Enable privacy mode?"
        defaultValue={false}
      />
      <BooleanInput
        source="privacyEnableOtherTeams"
        label="Enable privacy mode for other team?"
        defaultValue={false}
      />
    </SimpleForm>
  </Create>
);
