import {
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FilterButton,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  usePermissions,
  ReferenceInput,
  AutocompleteInput,
  NumberField,
  BooleanField,
  ReferenceField,
} from "react-admin";
import type {
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { Logger } from "@aws-amplify/core";
import { CustomList, ListPagination } from "./CustomList";
import { AmplifyFileField } from "./AmplifyFileField";
import { JsonField } from "react-admin-json-view";
import { useState } from "react";
import CreateTranscriptionAdminJobButton from "./StartTranscriptionAdminJobButton";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listTranscriptions";
const logger = new Logger("Transcription");

const filters = [
  <ReferenceInput
    source="teamId"
    reference="teams"
    label="Team"
    filterToQuery={(searchText) => ({
      listTeams: { name: searchText },
    })}
    perPage={2000}
    resettable
  >
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput
    source="meetingId"
    reference="meetings"
    label="Meeting"
    filterToQuery={(searchText) => ({
      listMeetings: { title: searchText },
    })}
    // filter={{ listTeamMeetingRelationshipsByTeamId: {} }} TODO try
    perPage={2000}
    resettable
  >
    <AutocompleteInput optionText="title" />
  </ReferenceInput>,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="startDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDate"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
  } = props;
  return (
    <TopToolbar className={className}>
      <CreateTranscriptionAdminJobButton />
      <FilterButton />
    </TopToolbar>
  );
};

export const TranscriptionList = (props: ListProps) => {
  const { loaded, permissions } = usePermissions();
  const [query, setQuery] = useState(defaultQuery);
  return (
    <CustomList
      {...props}
      filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <DateField source="date" showTime={true} sortable={true} />
        <TextField source="title" sortable={false} />
        <TextField source="team.name" sortable={false} label="Team name" />
        <TextField source="meeting.title" sortable={false} label="Meeting title" />
        <DateField source="startDateTime" showTime={true} sortable={false} />
        {(loaded && permissions.includes("superuser")) && (
          <DeleteWithConfirmButton />
        )}
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};


export const TranscriptionShow = (props: ShowProps) => {
  return (
    <Show
      {... props}
    >
      <SimpleShowLayout>
          <TextField source="id" label="ID" fullWidth />
          <DateField source="date" showTime={true} />
          <TextField source="title" />
          <TextField source="description" />
          <DateField showTime={true} source="startDateTime" />
          <DateField showTime={true} source="endDateTime" />
          <NumberField source="sizeInBytes" />
          <NumberField source="numberOfTranscriptions" />
          <BooleanField
            source="isZipFile"
            label="Is zip file?"
            defaultValue="No"
          />
          <AmplifyFileField
            source="result"
            download
            storageOptions={{ level: "public" }}
          />
          <JsonField
            source="result"
            addLabel={true}
            jsonString={false}
            reactJsonOptions={{
              theme: "shapeshifter",
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />
          <ReferenceField
            source="teamId"
            reference="teams"
            label="Team"
            link="show"
            fullWidth
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="meetingId"
            reference="meetings"
            label="Meeting"
            link="show"
            fullWidth
          >
            <TextField source="title" />
          </ReferenceField>
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}


