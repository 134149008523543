import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Button,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FilterButton,
  Link,
  ListButton,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  RefreshButton,
  required,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useDataProvider,
  useRecordContext,
  useNotify,
  useShowController,
  useRedirect,
  useMutation,
  DeleteWithConfirmButton,
  usePermissions,
  setAutomaticRefresh,
  NumberField,
  useRefresh,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
  ListActionsProps,
} from "react-admin";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import CheckIcon from "@material-ui/icons/Check";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { Theme, useMediaQuery } from "@material-ui/core";
import { SimpleList } from "@react-admin/ra-preferences/esm/src/no-code";
import { Logger } from "@aws-amplify/core";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Storage } from "@aws-amplify/storage";
import { DateTime } from "luxon";
import { customAlphabet } from "nanoid/async"; // @ts-ignore
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import type { RecordListEvent } from "@react-admin/ra-realtime";
import { RealTimeList, useSubscribe } from "@react-admin/ra-realtime";
import { JsonField } from "react-admin-json-view";
import {
  JobStatus,
  MeetingStatus,
  TeamMeetingRelationshipType,
} from "../API";
import type {
  Meeting,
  TeamMeetingRelationship,
} from "../API";
import {
  AWS_TIMESTAMP_AS_LUXON_FORMAT,
  DEFAULT_MEETING_ROOM_PROVIDER_ID,
  MEETING_STATUS_CHOICES,
} from "../constants";
import { MeetingRelationshipTypeField } from "./CustomFields";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { MeetingInvitationType } from "../API";
import { ListPagination } from "./CustomList";
import {
  downloadFileFromS3,
  getCurrentDateTime,
  getTranscriptionAdminJobByMeetingId,
  verifyMeeting,
} from "../lib/helpers";
import { MeetingsStateContext } from "../App";
import { onUpdateTranscriptionAdminJob, onUpdateMeeting } from "../graphql/subscriptions";
import { AmplifyFileField } from "./AmplifyFileField";
import {
  SellersMetricsIconField,
  BuyersMetricsIconField,
  MeetingMetricsIconField,
} from "./MetricsFields";
import TopToolbar from "./TopToolbar";
import { usePreferences } from "@react-admin/ra-preferences";


const defaultQuery = "listMeetings";
const logger = new Logger("Meeting");
const validateRequired = [required()];
const ReactSwal = withReactContent(Swal);


const filters = [
  <TextInput
    label="Title"
    source="title"
    resettable
  />,
  <SelectInput
    label="Status"
    source="status"
    choices={MEETING_STATUS_CHOICES}
    resettable={false}
    alwaysOn
    required
  />,
  <TextInput
    label="Company"
    source="sellerOrganizationName"
    resettable
  />,
  <TextInput
    label="Seller"
    source="sellerTeamName"
    resettable
  />,
  <TextInput
    label="Buyer"
    source="buyerTeamName"
    resettable
  />,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="startDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="endDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

/**
 * List actions
 *
 * @param {ListActionsProps} props
 * @returns {JSX.Element} react componment
 */
const ListActions = (props: ListActionsProps): JSX.Element => {
  const {
    className,
    basePath,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

/**
 * Create new team button
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const CreateNewTeamButton = (props: ShowProps | EditProps): JSX.Element => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teams/create",
    }}
    label="Create new team"
  >
    <ContactMailIcon />
  </CreateButton>
);

/**
 * Add seller team button
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const AddSellerTeamMeetingRelationshipButton = (props: ShowProps | EditProps): JSX.Element => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamMeetingRelationships/create",
      state: {
        record: {
          meetingId: props.id as string,
          relationshipType: TeamMeetingRelationshipType.ORGANIZER,
        }
      },
      search: `?source=${JSON.stringify(
        {
          meetingId: props.id as string,
          relationshipType: TeamMeetingRelationshipType.ORGANIZER,
        }
      )}`,
    }}
    label="Add seller team"
  >
    <ContactMailIcon />
  </CreateButton>
);

/**
 * Add buyer team button
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const AddBuyerTeamMeetingRelationshipButton = (props: ShowProps | EditProps): JSX.Element => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/teamMeetingRelationships/create",
      state: {
        record: {
          meetingId: props.id as string,
          relationshipType: TeamMeetingRelationshipType.INVITEE,
        }
      },
      search: `?source=${JSON.stringify(
        {
          meetingId: props.id as string,
          relationshipType: TeamMeetingRelationshipType.INVITEE,
        }
      )}`,
    }}
    label="Add buyer team"
  >
    <ContactMailIcon />
  </CreateButton>
);

/**
 * Add buyer or seller team button
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const AddNewTeamMembershipRelationshipButton = (props: ShowProps | EditProps): JSX.Element => {

  const record = useRecordContext();
  logger.info("AddNewTeamMembershipRelationshipButton record", record);
  if (!record) {
    return <></>;
  }
  return (
    <CreateButton
      component={Link}
      to={{
        pathname: "/teamMemberRelationships/create",
        state: { record: { teamId: record.teamId } },
        search: `?source=${JSON.stringify({ teamId: record.teamId as string })}`,
      }}
      label="Add member"
    >
      <ContactMailIcon />
    </CreateButton>
  );
}

/**
 * Redirect to meeting scheduler
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const MeetingSchedulerButton = (props: ShowProps | EditProps): JSX.Element => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  return (
    <EditButton
      component={Link}
      to={{
        pathname: `/scheduler/${record?.id}`,
      }}
      label="Scheduler"
      disabled={record && record.status !== MeetingStatus.SCHEDULED}
    >
      <ContactMailIcon />
    </EditButton>
  );
}

/**
 * Start meeting verification
 *
 * @param {meetingId} string
 * @returns {Promise<{ meetingId: string, id?: string }>} the result
 */
const startMeetingVerification = async (
  meetingId: string,
  profileId: string,
): Promise<{ meetingId: string, id?: string }> => {
  logger.info("got meetingId", meetingId);
  return verifyMeeting(meetingId, profileId).then(
    (result) => {
      logger.info("verifyMeeting result", result);
      return result;
    }
  ).catch(
    (err) => {
      logger.error("startMeetingVerification error", err);
      return {
        meetingId,
      };
    }
  );
}

/**
 * Add meeting ID to verification queue
 *
 * @param {ShowProps | EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
const MeetingVerificationButton = (props: ShowProps | EditProps): JSX.Element => {
  const record = useRecordContext();
  if (!record) {
    return <></>;
  }
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  return (
    <Button
      onClick={() => {
        startMeetingVerification(
          `${record?.id}`,
          `${currentProfile?.id}`,
        );
      }}
      label="Start Validation"
      disabled={record && record.status !== MeetingStatus.SCHEDULED}
    >
      <CheckIcon />
    </Button>
  );
}

const meetingRowSx = (record: any, index: number) => ({
  backgroundColor: (!record.productId && record.status == MeetingStatus.SCHEDULED) ? '#ec7063' : undefined,
});

/**
 * React Admin listing component for the Meeting resource
 *
 * @param {ListProps} props componment properties
 * @returns {JSX.Element} react componment
 */
export const MeetingList = (props: ListProps): JSX.Element => {
  const [query, setQuery] = useState(defaultQuery);
  const refresh = useRefresh();
  const notify = useNotify();
  // const urlQuery = urlQuery();
  // see: https://marmelab.com/react-admin/Tutorial.html#supporting-mobile-devices
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const meetingsState = useContext(MeetingsStateContext);
  const {
    currentProfile,
  } = meetingsState;
  const dataProvider = useDataProvider();
  const [ clockSyncEnabled ] = usePreferences("clockSyncEnabled", false);
  const [ cachedServerTimeDifference ] = usePreferences("cachedServerTimeDifference", 0);
  const [ currentDateTime, setCurrentDateTime ] = useState(getCurrentDateTime(cachedServerTimeDifference, clockSyncEnabled));
  const handleEventReceived = (event: RecordListEvent) => {
    // const count = get(event, "payload.ids.length", 1);
    logger.info("handleEventReceived event", event);
    // 'List refreshed with %{smart_count} %{name} %{type} by another user'
    // notify("ra-realtime.notification.list.refreshed", "info", event);
    notify("ra-realtime.action.refresh", "info");
    refresh();
  };

  useSubscribe("resource/exportJobAuditLogs", (event) => {
    logger.info("useSubscribe event", event);
    // TODO download file
  });

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const subscription = API.graphql(
          graphqlOperation(onUpdateMeeting, { owner: currentProfile?.userId })
        ).subscribe({
          next: ({ value }: any) => {
            logger.info("value.data.onUpdateMeeting", value.data.onUpdateMeeting);
            logger.info("value.data.onUpdateMeeting.id", value.data.onUpdateMeeting.id);
            const {
              metrics,
            } = value.data.onUpdateMeeting;
            if (metrics) {
              logger.info("metrics", metrics);
            }
            dataProvider.publish(
              "resource/meetings",
              {
                type: "updated",
                topic: "resource/meetings",
                payload: { ids: [ value.data.onUpdateMeeting.id ]},
                date: new Date(),
              },
            );
          },
          error: (error: Error) => {
            logger.warn("onUpdateMeeting failed", error);
          }
        });
        return () => {
          subscription.unsubscribe();
        }
      }
    },
    [ currentProfile?.userId ]
  );

  useEffect(
    () => {
      if (currentProfile?.userId) {
        const meetingLoggedInCheckerInterval = setInterval(
          () => {
            setCurrentDateTime(
              getCurrentDateTime(
                cachedServerTimeDifference,
                clockSyncEnabled,
              ),
            );
          },
          1000 * 60,
        );
        return () => {
          if (meetingLoggedInCheckerInterval) {
            try {
              clearInterval(meetingLoggedInCheckerInterval);
            } catch {
              (err: Error) => { }
            }
          }
        }
      }
    },
    [ currentProfile?.userId ]
  );

  return (
    <RealTimeList
      {...props}
      filters={filters}
      actions={<ListActions />}
      filterDefaultValues={{ status: "SCHEDULED" }}
      bulkActionButtons={false}
      onEventReceived={handleEventReceived}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      {isSmall ? (
        <SimpleList
          primaryText={record => record.title}
          secondaryText={record => new Date(record.startDateTime).toLocaleDateString()}
          tertiaryText={record => new Date(record.endDateTime).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowStyle={meetingRowSx}>
          <MeetingMetricsIconField
            source="metrics"
            label={""}
            jsonString={true}
            currentDateTime={currentDateTime}
          />
          <TextField source="title" sortable={false} />
          <TextField source="sellerOrganizationName" label="Company" sortable={false} />
          <SellersMetricsIconField
            source="metrics"
            label={""}
            jsonString={true}
            currentDateTime={currentDateTime}
          />
          <TextField source="sellerTeamName" label="Seller" sortable={false} />
          <BuyersMetricsIconField
            source="metrics"
            label={""}
            jsonString={true}
            currentDateTime={currentDateTime}
          />
          <TextField source="buyerTeamName" label="Buyer" sortable={false} />
          <DateField source="startDateTime" showTime={true} sortable={true} />
          <DateField source="endDateTime" showTime={true} sortable={false} />
          <ShowButton />
          <EditButton />
          <MeetingSchedulerButton />
        </Datagrid>
      )}
    </RealTimeList>
  );
};

/**
 * React Admin show component for the Meeting resource
 *
 * @param {ShowProps} props componment properties
 * @returns {JSX.Element} react componment
 */
export const MeetingShow = (props: ShowProps) => {

  const [buyerTeamRelationship, setBuyerTeamRelationship] = useState<TeamMeetingRelationship|null|boolean>(null);
  const [sellerTeamRelationship, setSellerTeamRelationship] = useState<TeamMeetingRelationship|null|boolean>(null);
  const [ transcriptionEnabled, setTranscriptionEnabled ] = useState<Map<string, boolean>>(new Map());
  const [ transcriptionButtonDisabled, setTranscriptionButtonDisabled ] = useState<Map<string, boolean>>(new Map());
  const [ transcriptionIsReady, setTranscriptionIsReady ] = useState<Map<string, boolean>>(new Map());

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record } = useShowController(props);
  const { loaded, permissions } = usePermissions();

  useEffect(
    () => {
      const {
        id,
      } = props;
      if (id && record) {
        if (record.status === MeetingStatus.SCHEDULED) {
          let buyer: TeamMeetingRelationship | null  = null;
          let seller: TeamMeetingRelationship | null = null;
          dataProvider.getManyReference(
            "teamMeetingRelationships",
            {
              target: "meetingId",
              id: `${id}`,
              pagination: { page: 1, perPage: 10 },
              sort: { field: "createdAt", order: "DESC" },
              filter: {},
            }
          ).then(
            (results) => {
              const {
                data,
              } = results;
              if (data && data.length > 0) {
                for (let i = 0; i < data.length; i = i + 1) {
                  const relationship = data[i] as TeamMeetingRelationship;
                  if (!buyer && relationship.relationshipType === TeamMeetingRelationshipType.INVITEE) {
                    buyer = relationship;
                    setBuyerTeamRelationship(relationship);
                  } else if (!seller && relationship.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
                    seller = relationship;
                    setSellerTeamRelationship(relationship);
                  }
                }
              }
            }
          ).catch(
            (err: Error) => {
              notify(err.message, { type: "warning" });
              logger.error("teamMeetingRelationships error", err);
            }
          );
        } else if (record.status === MeetingStatus.ENDED) {
          setBuyerTeamRelationship(true);
          setSellerTeamRelationship(true);
          if (record.transcriptionEnabled && !record.privacyEnabled) {
            const updatedTranscriptionEnabled = new Map(transcriptionEnabled);
            updatedTranscriptionEnabled.set(`${id}`, true);
            logger.info(`updatedTranscriptionEnabled.set(${id}, true)`);
            setTranscriptionEnabled(updatedTranscriptionEnabled);
          }
        }
      }
    },
    [props.id, record?.status]
  );

  const ShowActions = (props: ShowProps) => {
    const {
      className,
      basePath,
      id,
    } = props;
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const meetingsState = useContext(MeetingsStateContext);
    const {
      currentProfile,
    } = meetingsState;

    /**
     * Download transcription for meeting
     *
     * @param {DownloadTranscriptionProps} props componment properties
     * @returns {JSX.Element} react componment
     */
    const DownloadTranscriptionButton = (props: ShowProps): JSX.Element => {
      const record = useRecordContext();
      if (!record) {
        return <></>;
      }
      const notify = useNotify();
      const redirect = useRedirect();
      const dataProvider = useDataProvider();
      const meetingsState = useContext(MeetingsStateContext);
      const {
        currentProfile,
      } = meetingsState;

      const downloadTranscriptionOrCreateTranscriptionAdminJob = async (
        meetingId: string,
      ) => {
        logger.info("downloadTranscriptionOrCreateTranscriptionAdminJob meetingId", meetingId);
        await getTranscriptionAdminJobByMeetingId(
          meetingId,
          dataProvider,
          currentProfile?.userId,
        ).then(
          async (transcriptionAdminJob) => {
            logger.info("downloadTranscriptionOrCreateTranscriptionAdminJob transcriptionAdminJob", transcriptionAdminJob);
            if ((!transcriptionAdminJob || transcriptionAdminJob.status === JobStatus.FAILED) && currentProfile) {
              await Auth.currentUserCredentials().then(
                async (creds) => {
                  logger.info("creds", creds);
                  const nowFormatted = DateTime.utc().toFormat(AWS_TIMESTAMP_AS_LUXON_FORMAT);
                  const input = {
                    date: nowFormatted,
                    status: JobStatus.PROCESSING,
                    meetingId,
                    owner: currentProfile.userId,
                    identityId: creds.identityId,
                  };
                  logger.info("TranscriptionAdminJob input", input);
                  await dataProvider.create(
                    "transcriptionAdminJobs",
                    {
                      data: input,
                    }
                  )
                  .then(
                    (createResult) => {
                      logger.info("TranscriptionAdminJob creation result", createResult);
                      redirect(`/transcriptionAdminJobAuditLogs?displayedFilters=%7B%22transcriptionAdminJobId%22%3Atrue%7D&filter=%7B%22transcriptionAdminJobId%22%3A%22${createResult.data.id}%22%7D&order=ASC&page=1&perPage=200&sort=id`);
                    }
                  ).catch(
                    (err) => {
                      logger.error("TranscriptionAdminJob creation error", err);
                      ReactSwal.fire(
                        "Transcription creation error.",
                        `${err}`,
                        "warning"
                      );
                    }
                  );
                }
              )
            } else if (transcriptionAdminJob)  {
              logger.info("downloadTranscriptionOrCreateTranscriptionAdminJob transcriptionAdminJob", transcriptionAdminJob);
              const { result } = transcriptionAdminJob;
              logger.info("transcriptionAdminJob result", result);
              if (transcriptionAdminJob.status === JobStatus.FAILED) {
                notify("Transcription failed to generate.", { type: "warning" });
              } else if (result) {
                const {
                  key,
                } = result;
                logger.info("transcriptionAdminJob key", key);
                if (key) {
                  downloadFileFromS3(key, Storage, "public");
                  const updatedTranscriptionIsReady = new Map(transcriptionIsReady);
                  updatedTranscriptionIsReady.set(meetingId, true);
                  setTranscriptionIsReady(updatedTranscriptionIsReady);
                } else {
                  notify("Transcription is not available.", { type: "warning" });
                }
                const updatedTranscriptionButtonDisabled = new Map(transcriptionButtonDisabled);
                updatedTranscriptionButtonDisabled.set(meetingId, false);
                setTranscriptionButtonDisabled(updatedTranscriptionButtonDisabled);
                try {
                  // NOTE: cancel alert
                  ReactSwal.close();
                } catch(err: any) { }
              } else {
                notify("Transcription job failed to complete.", { type: "warning" });
              }
            }
          }
        ).catch(
          (err) => {
            logger.error('getTranscriptionAdminJobByMeetingId error', err);
            notify(err.message, { type: "warning" });
          }
        );
      };

      return (
        <Button
          label={transcriptionIsReady.get(`${record.id}`) === true ? 'Download the Transcript' : 'Transcript'}
          type="button"
          disabled={transcriptionButtonDisabled.get(`${record.id}`) === true}
          color={transcriptionIsReady.get(`${record.id}`) === true ? 'secondary' : 'primary'}
          variant={transcriptionIsReady.get(`${record.id}`) === true ? 'contained' : undefined}
          onClick={() => {
            downloadTranscriptionOrCreateTranscriptionAdminJob(
              `${record.id}`
            )
          }}
        >
          <SpeakerNotesIcon />
        </Button>
      );
    }

    useEffect(
      () => {
        if (currentProfile?.userId) {
          const subscription = API.graphql(
            graphqlOperation(onUpdateTranscriptionAdminJob, { owner: currentProfile?.userId })
          ).subscribe({
            next: ({ value }: any) => {
              logger.info("value.data.onUpdateTranscriptionAdminJob", value.data.onUpdateTranscriptionAdminJob);
              logger.info("value.data.onUpdateTranscriptionAdminJob.id", value.data.onUpdateTranscriptionAdminJob.id);
              const {
                id,
                meetingId,
                result,
                status,
              } = value.data.onUpdateTranscriptionAdminJob;
              logger.info("job id", id);
              logger.info("meetingId", meetingId);
              logger.info("result", result);
              logger.info("status", status);
              try {
                // NOTE: cancel alert
                ReactSwal.close();
              } catch(err: any) { }
              if (result && status === JobStatus.COMPLETED) {
                // notify("Job is complete.", { type: "success" });
                const {
                  key,
                } = result;
                logger.info("key", key);
                if (key) {
                  // NOTE: auto download will not work in some browsers
                  ReactSwal.fire(
                    {
                      title: 'Transcription',
                      text: 'Complete!',
                      icon: 'success',
                      showCancelButton: true,
                      confirmButtonText: 'Yes, download!',
                      confirmButtonColor: "#056839",
                      cancelButtonColor: "#39B54A",
                    }
                  ).then((result) => {
                    if (result.isConfirmed) {
                      downloadFileFromS3(key, Storage, "public");
                    }
                  });
                  const updatedTranscriptionIsReady = new Map(transcriptionIsReady);
                  updatedTranscriptionIsReady.set(meetingId, true);
                  setTranscriptionIsReady(updatedTranscriptionIsReady);
                } else {
                  notify("Transcription is not available.", { type: "warning" });
                }
              } else {
                logger.info("status is not completed", status);
                notify(`Job status is ${status}`, { type: "warning" });
              }
              const updatedTranscriptionButtonDisabled = new Map(transcriptionButtonDisabled);
              updatedTranscriptionButtonDisabled.set(meetingId, false);
              setTranscriptionButtonDisabled(updatedTranscriptionButtonDisabled);
              dataProvider.publish(
                "resource/transcriptionAdminJobs",
                {
                  type: "updated",
                  topic: "resource/transcriptionAdminJobs",
                  payload: { ids: [ value.data.onUpdateTranscriptionAdminJob.id ]},
                  date: new Date(),
                },
              );
            },
            error: (err: Error) => {
              logger.warn("onUpdateTranscriptionAdminJob failed", err);
              notify(err.message, { type: "warning" });
            }
          });
          return () => {
            subscription.unsubscribe();
          }
        }
      },
      [ currentProfile?.userId ]
    );

    return (
      <TopToolbar className={className}>
        <ListButton basePath={basePath} />
        <RefreshButton />
        {!sellerTeamRelationship || !buyerTeamRelationship &&
          <CreateNewTeamButton {...props} />
        }
        {!sellerTeamRelationship &&
          <AddSellerTeamMeetingRelationshipButton {...props} />
        }
        {!buyerTeamRelationship &&
          <AddBuyerTeamMeetingRelationshipButton {...props} />
        }
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
        <MeetingSchedulerButton />
        <MeetingVerificationButton />
        {transcriptionEnabled.get(`${id}`) === true &&
          <DownloadTranscriptionButton />
        }
      </TopToolbar>
    );
  };

  return (
    <Show {...props} actions={<ShowActions {...props} />}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" label="ID" fullWidth />
          <TextField source="title" />
          <TextField source="status" />
          <TextField source="description" />
          <DateField showTime={true} source="startDateTime" />
          <DateField showTime={true} source="endDateTime" />
          <DateField showTime={true} source="actualStartDateTime" />
          <DateField showTime={true} source="actualEndDateTime" />
          <TextField source="sellerOrganizationName" label="Company" />
          <TextField source="sellerTeamName" label="Seller" />
          <TextField source="buyerTeamName" label="Buyer" />
          <BooleanField
            source="videoEnabled"
            label="Video enabled?"
            defaultValue="No"
          />
          <BooleanField
            source="privacyEnabled"
            label="Enable privacy mode?"
            defaultValue="No"
          />
          <BooleanField
            source="transcriptionEnabled"
            label="Enable transcription?"
            defaultValue="No"
          />
          <BooleanField
            source="isReadinessTest"
            label="Is readiness test?"
            defaultValue="No"
          />
          <ReferenceField
            source="preferredRoomProviderId"
            reference="meetingRoomProviders"
            label="Preferred room provider"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="meetingRoomMeetingId"
            reference="meetingRooms"
            label="Room"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="productId"
            reference="products"
            label="Product"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            source="externalId"
            label="Meeting UID"
          />
          <JsonField
            source="metrics"
            addLabel={true}
            jsonString={true}
            reactJsonOptions={{
              theme: "shapeshifter",
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
        </Tab>
        <Tab label="Buyers and Sellers by Team">
          <ReferenceManyField
              reference="teamMeetingRelationships"
              target="meetingId"
              label="Teams"
              perPage={25}
              fullWidth
              filter={{ listTeamMeetingRelationshipsByMeetingId: {} }}
            >
              <Datagrid>
                <ReferenceField
                  source="teamId"
                  reference="teams"
                  label="Team"
                  link="show"
                >
                  <TextField source="name" />
                </ReferenceField>
                <TextField source="meeting.title" sortable={false} label="Meeting title" />
                <MeetingRelationshipTypeField source="relationshipType" sortable={false} label="Seller / Buyer" />
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/meetings/${data.meetingId}/show/3`;
                      return "/meetings";
                    }
                  }
                />
                {(loaded && permissions.includes("superuser")) && (
                <ShowButton />
                )}
                <AddNewTeamMembershipRelationshipButton />
              </Datagrid>
            </ReferenceManyField>
        </Tab>
        {false && (<Tab label="Seller Team">
          <ReferenceManyField
              reference="teamMeetingRelationships"
              target="meetingId"
              label="Teams"
              perPage={1}
              page={1}
              fullWidth
              filter={
                {
                  listTeamMeetingRelationshipsByMeetingIdAndRelationshipType: {
                    relationshipType: {
                      eq: TeamMeetingRelationshipType.ORGANIZER
                    }
                  }
                }
              }
            >
              <Datagrid>
                <TextField source="team.name" sortable={false} label="Team name" />
                <TextField source="meeting.title" sortable={false} label="Meeting title" />
                <MeetingRelationshipTypeField source="relationshipType" sortable={false} label="Seller / Buyer" />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
        </Tab>)}
        {false && (<Tab label="Buyer Team">
          <ReferenceManyField
              reference="teamMeetingRelationships"
              target="meetingId"
              label="Teams"
              perPage={1}
              page={1}
              fullWidth
              filter={
                {
                  listTeamMeetingRelationshipsByMeetingIdAndRelationshipType: {
                    relationshipType: {
                      eq: TeamMeetingRelationshipType.INVITEE
                    }
                  }
                }
              }
            >
              <Datagrid>
                <TextField source="team.name" sortable={false} label="Team name" />
                <TextField source="meeting.title" sortable={false} label="Meeting title" />
                <MeetingRelationshipTypeField source="relationshipType" sortable={false} label="Seller / Buyer" />
                <ShowButton />
              </Datagrid>
            </ReferenceManyField>
        </Tab>)}
        <Tab label="Buyers and Sellers by Individual">
          <ReferenceManyField
              reference="meetingInvites"
              target="meetingId"
              label="Meeting invitees"
              perPage={25}
              fullWidth
              filter={{ listMeetingInvitesByMeetingId: {} }}
            >
              <Datagrid>
                <ReferenceField
                  source="profileId"
                  reference="profiles"
                  label="User"
                  link="show"
                >
                  <TextField source="fullName" />
                </ReferenceField>
                <ReferenceField
                  source="teamId"
                  reference="teams"
                  label="Team"
                  link="show"
                >
                  <TextField source="name" />
                </ReferenceField>
                <MeetingRelationshipTypeField source="meetingInvitationType" label="Seller / Buyer" />
                {(loaded && permissions.includes("superuser")) && (
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/meetings/${data.meetingId}/show/3`;
                      return "/meetings";
                    }
                  }
                />
                )}
                {(loaded && permissions.includes("superuser")) && (
                <ShowButton />
                )}
              </Datagrid>
            </ReferenceManyField>
        </Tab>
        <Tab label="Attendees">
          <ReferenceManyField
              reference="meetingAttendees"
              target="meetingId"
              label="Meeting attendees"
              perPage={25}
              fullWidth
              filter={{ listMeetingAttendeesByMeetingId: {} }}
            >
              <Datagrid>
                <ReferenceField
                  source="profileId"
                  reference="profiles"
                  label="User"
                  link="show"
                >
                  <TextField source="fullName" />
                </ReferenceField>
                <DateField source="createdAt" showTime={true} label="Joined at" />
              </Datagrid>
            </ReferenceManyField>
        </Tab>
        <Tab label="Transcriptions">
          <ReferenceManyField  // TODO only show if transcription is enabled
              reference="transcriptions"
              target="meetingId"
              label="Transcriptions"
              perPage={25}
              fullWidth
              filter={{ listTranscriptionsByMeetingId: {} }}
            >
              <Datagrid>
                <TextField source="title" />
                <AmplifyFileField
                  source="result"
                  download
                  storageOptions={{ level: "public" }}
                  label="File"
                />
                <NumberField source="sizeInBytes" />
                <DateField source="createdAt" showTime={true} />
                {(loaded && permissions.includes("superuser")) && (
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/meetings/${data.meetingId}/show/3`;
                      return "/meetings";
                    }
                  }
                />
                )}
                {(loaded && permissions.includes("superuser")) && (
                <ShowButton />
                )}
              </Datagrid>
            </ReferenceManyField>
        </Tab>
        {false && (<Tab label="Buyers">
          <ReferenceManyField
              reference="meetingInvites"
              target="meetingId"
              label="Meeting invitees"
              perPage={25}
              fullWidth
              filter={
                {
                  listMeetingInvitesByMeetingIdAndMeetingInvitationType: { // TODO add new index then list by buy and seller
                    meetingInvitationType: {
                      eq: MeetingInvitationType.ORGANIZER
                    }
                  }
                }
              }
            >
              <Datagrid>
                <ReferenceField
                  source="profileId"
                  reference="profiles"
                  label="User"
                  link="show"
                >
                  <TextField source="fullName" />
                </ReferenceField>
                <MeetingRelationshipTypeField source="meetingInvitationType" label="Type" />
                <DeleteWithConfirmButton
                  redirect={
                    (deleteResults) => {
                      // TODO redirect to `/meetings/${data.meetingId}/show/3`;
                      return "/meetings";
                    }
                  }
                />
              </Datagrid>
            </ReferenceManyField>
        </Tab>)}
      </TabbedShowLayout>
    </Show>
  );
}

/**
 * React Admin edit component for the Meeting resource
 *
 * @param {EditProps} props componment properties
 * @returns {JSX.Element} react componment
 */
export const MeetingEdit = (props: EditProps) => {

  const [buyerTeamRelationship, setBuyerTeamRelationship] = useState<TeamMeetingRelationship|null|boolean>(null);
  const [sellerTeamRelationship, setSellerTeamRelationship] = useState<TeamMeetingRelationship|null|boolean>(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { record } = useShowController(props);
  const redirect = useRedirect();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));
  const validateExistingMeeting = (data: any) => {
    let errors: any = {};

    let {
      startDateTime,
      endDateTime,
    } = data;

    if (!startDateTime) {
      const errorMessage = "Start date and time is required.";
      errors = { startDateTime: errorMessage };
      return errors;
    }
    if (typeof startDateTime === "string") {
      startDateTime = new Date(Date.parse(startDateTime));
    }
    const now = new Date();

    if (!endDateTime) {
      const errorMessage = "End date and time is required.";
      errors = { endDateTime: errorMessage };
      return errors;
    }
    if (typeof endDateTime === "string") {
      endDateTime = new Date(Date.parse(endDateTime));
    }
    if (startDateTime.getTime() >= endDateTime.getTime()) {
      const errorMessage = "Start date must be before the end date.";
      errors = { startDateTime: errorMessage };
    }

    const durationInMinutes = Math.floor(endDateTime.getTime() - startDateTime.getTime()) / (60 * 1000);

    if (durationInMinutes > 60) {
      const errorMessage = "Meeting duration is over 1 hour.";
      errors = { endDateTime: errorMessage };
    }

    return errors;
  };

  const onSuccess = (meetingData: { data: Meeting }) => {
    const {
      data,
    } = meetingData;
    let message = "Meeting updated";
    notify(message, { type: "success" });
    redirect(`/meetings/${data.id}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = validateExistingMeeting(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      try {
        if (data["startDateTime"].getSeconds() > 0) {
          data["startDateTime"].setSeconds(0);
        }
        if (data["endDateTime"].getSeconds() > 0) {
          data["endDateTime"].setSeconds(0);
        }
      } catch(err) {
        logger.warn("failed to set seconds");
      }
      try {
        if (data["startDateTime"].getMilliseconds() > 0) {
          data["startDateTime"].setMilliseconds(0);
        }
        if (data["endDateTime"].getMilliseconds() > 0) {
          data["endDateTime"].setMilliseconds(0);
        }
      } catch(err) {
        logger.warn("failed to set milliseconds");
      }
      data["titleLowerCase"] = `${data["title"]}`.toLowerCase();
      await mutate(
        {
          type: "update",
          resource: "meetings",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  useEffect( // TODO make a component or function
    () => {
      const {
        id,
      } = props;
      if (record) {
        if (record.status === MeetingStatus.SCHEDULED) {
          let buyer: TeamMeetingRelationship | null  = null;
          let seller: TeamMeetingRelationship | null = null;
          dataProvider.getManyReference(
            "teamMeetingRelationships",
            {
              target: "meetingId",
              id: `${id}`,
              pagination: { page: 1, perPage: 10 },
              sort: { field: "createdAt", order: "DESC" },
              filter: {},
            }
          ).then(
            (results) => {
              const {
                data,
              } = results;
              if (data && data.length > 0) {
                for (let i = 0; i < data.length; i = i + 1) {
                  const relationship = data[i] as TeamMeetingRelationship;
                  if (!seller && relationship.relationshipType === TeamMeetingRelationshipType.ORGANIZER) {
                    seller = relationship;
                    setSellerTeamRelationship(relationship);
                  } else if (!buyer && relationship.relationshipType === TeamMeetingRelationshipType.INVITEE) {
                    buyer = relationship;
                    setBuyerTeamRelationship(relationship);
                  }
                }
              }
            }
          ).catch(
            (err: Error) => {
              notify(err.message, { type: "warning" });
              logger.error("teamMeetingRelationships error", err);
            }
          );
        } else {
          setSellerTeamRelationship(true);
          setBuyerTeamRelationship(true);
        }
      }
    },
    [props.id, record?.status]
  );

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id
    } = props;
    return (
      <TopToolbar className={className}>
        <ListButton basePath={basePath} />
        {!sellerTeamRelationship || !buyerTeamRelationship &&
          <CreateNewTeamButton {...props} />
        }
        {!sellerTeamRelationship &&
          <AddSellerTeamMeetingRelationshipButton {...props} />
        }
        {!buyerTeamRelationship &&
          <AddBuyerTeamMeetingRelationshipButton {...props} />
        }
        <ShowButton
          basePath={basePath}
          label="Show"
          record={{ id: id as string }}
        />
        <MeetingVerificationButton />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        save={save}
        toolbar={<DeleteWithConfirmToolbar />}
      >
        <TextInput source="id" label="ID" disabled fullWidth />
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <SelectInput source="status" choices={MEETING_STATUS_CHOICES} />
        <MaterialDateTimeInput
          source="startDateTime"
          validate={required("Start date and time is required.")}
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired
        />
        <MaterialDateTimeInput
          source="endDateTime"
          validate={required("End date and time is required.")}
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired
        />
        <TextInput source="description" fullWidth />
        <TextInput source="sellerOrganizationName" label="Company" fullWidth />
        <TextInput source="sellerTeamName" label="Seller" fullWidth />
        <TextInput source="buyerTeamName" label="Buyer" fullWidth />
        <BooleanInput
          source="videoEnabled"
          label="Video enabled?"
          defaultValue={false}
        />
        <BooleanInput
          source="privacyEnabled"
          label="Enable privacy mode?"
          defaultValue={false}
        />
        <BooleanInput
          source="transcriptionEnabled"
          label="Enable transcription?"
          defaultValue={false}
        />
        <BooleanInput
          source="isReadinessTest"
          label="Is readiness test?"
          defaultValue={false}
        />
        <ReferenceInput
          source="preferredRoomProviderId"
          reference="meetingRoomProviders"
          label="Preferred room provider"
          filterToQuery={(searchText) => ({
            listMeetingRoomProviders: {
              componentName: searchText,
            },
          })}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="componentName" />
        </ReferenceInput>
        <ReferenceInput
          source="productId"
          reference="products"
          label="Product"
          filterToQuery={(searchText) => ({
            listProducts: {
              name: searchText,
            },
          })}
          perPage={2000}
          alwaysOn
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput
          source="externalId"
          label="Meeting UID"
        />
      </SimpleForm>
    </Edit>
  );
}

export const MeetingCreate = (props: CreateProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();
  const dispatch = useDispatch();
  dispatch(setAutomaticRefresh(false));
  const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNPQRSTUVWXYZ", 9);

  const validateNewMeeting = (data: any) => {
    let errors: any = {};

    let {
      startDateTime,
      endDateTime,
    } = data;

    if (!startDateTime) {
      const errorMessage = "Start date and time is required.";
      errors = { startDateTime: errorMessage };
      return errors;
    }
    if (typeof startDateTime === "string") {
      startDateTime = new Date(Date.parse(startDateTime));
    }
    const now = new Date();
    if (startDateTime.getTime() < now.getTime()) {
      const errorMessage = "Start date and time can not be in the past.";
      errors = { startDateTime: errorMessage };
    }

    if (!endDateTime) {
      const errorMessage = "End date and time is required.";
      errors = { endDateTime: errorMessage };
      return errors;
    }
    if (typeof endDateTime === "string") {
      endDateTime = new Date(Date.parse(endDateTime));
    }
    if (startDateTime.getTime() >= endDateTime.getTime()) {
      const errorMessage = "Start date must be before the end date.";
      errors = { startDateTime: errorMessage };
    }

    const durationInMinutes = Math.floor(endDateTime.getTime() - startDateTime.getTime()) / (60 * 1000);

    if (durationInMinutes > 60) {
      const errorMessage = "Meeting duration is over 1 hour.";
      errors = { endDateTime: errorMessage };
    }

    return errors;
  };

  const onSuccess = (meetingData: { data: Meeting }) => {
    const {
      data,
    } = meetingData;
    let message = "Meeting created";
    notify(message, { type: "success" });
    redirect(`/meetings/${data.id}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: any) => {
      const errors = validateNewMeeting(data);
      if (Object.keys(errors).length > 0) {
        logger.warn("found validation errors", errors);
        return errors;
      }
      try {
        if (data["startDateTime"].getSeconds() > 0) {
          data["startDateTime"].setSeconds(0);
        }
        if (data["endDateTime"].getSeconds() > 0) {
          data["endDateTime"].setSeconds(0);
        }
      } catch(err) {
        logger.warn("failed to set seconds");
      }
      try {
        if (data["startDateTime"].getMilliseconds() > 0) {
          data["startDateTime"].setMilliseconds(0);
        }
        if (data["endDateTime"].getMilliseconds() > 0) {
          data["endDateTime"].setMilliseconds(0);
        }
      } catch(err) {
        logger.warn("failed to set milliseconds");
      }
      // TODO set external ID with random uuid
      const {
        externalId,
      } = data;
      if (!externalId) {
        try {
          data["externalId"] = await nanoid().then((generatedId) => generatedId);
        } catch(err) { }
      }
      data["titleLowerCase"] = `${data["title"]}`.toLowerCase();
      await mutate(
        {
          type: "create",
          resource: "meetings",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Create {...props}>
      <SimpleForm
        save={save}
        initialValues={
          {
            preferredRoomProviderId: DEFAULT_MEETING_ROOM_PROVIDER_ID,
            status: MeetingStatus.SCHEDULED,
          }
        }
      >
        <TextInput
          source="title"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput source="description" fullWidth />
        <MaterialDateTimeInput // TODO add helper divs
          source="startDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          validate={required("Start date and time is required.")}
          isRequired
        />
        <MaterialDateTimeInput
          source="endDateTime"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          validate={required("End date and time is required.")}
          isRequired
        />
        <BooleanInput
          source="videoEnabled"
          label="Video enabled?"
          defaultValue={true}
        />
        <BooleanInput
          source="privacyEnabled"
          label="Enable privacy mode?"
          defaultValue={false}
        />
        <BooleanInput
          source="transcriptionEnabled"
          label="Enable transcription?"
          defaultValue={true}
        />
        <BooleanInput
          source="isReadinessTest"
          label="Is readiness test?"
          defaultValue={false}
        />
        <ReferenceInput
          source="productId"
          reference="products"
          label="Product"
          filterToQuery={(searchText) => ({
            listProducts: {
              name: searchText,
            },
          })}
          perPage={2000}
          alwaysOn
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" choices={MEETING_STATUS_CHOICES} />
        <ReferenceInput
          source="preferredRoomProviderId"
          reference="meetingRoomProviders"
          label="Preferred room provider"
          filterToQuery={(searchText) => ({
            listMeetingRoomProviders: {
              componentName: searchText,
            },
          })}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="componentName" />
        </ReferenceInput>
        <TextInput
          source="externalId"
          label="Meeting UID"
        />
      </SimpleForm>
    </Create>
  );
}
