import React, { useContext, useState } from "react";
import {
  AutocompleteInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  FilterButton,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  useNotify,
  usePermissions,
  useQuery,
  useRedirect,
  useShowController,
  required,
} from "react-admin";
import type {
  CreateProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { AmplifyFileField } from "./AmplifyFileField";
import { AmplifyFileInput } from "./AmplifyFileInput";
import { ImportJob, JobStatus } from "../API";
import { Logger } from "@aws-amplify/core";
import { Auth } from "aws-amplify";
import { MeetingsStateContext } from "../App";
import { LogPayloadField } from "./CustomFields";
import { Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineSkeleton,
} from "@react-admin/ra-audit-log";
import { JsonField } from "react-admin-json-view";
import { CustomTimelineGroup } from "./CustomTimeline";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listImportJobs";
const jobStatuses = [
  { name: "Pending", id: "PENDING" },
  { name: "Processing", id: "PROCESSING" },
  { name: "Failed", id: "FAILED" },
  { name: "Cancelled", id: "CANCELLED" },
  { name: "Completed", id: "COMPLETED" },
];

const logger = new Logger("ImportJob");
const validateRequired = [required()];

interface AsideProps extends ShowProps {
  record?: ImportJob;
}

/*

// import { AmplifyFilter } from "./AmplifyFilter";

export const ImportJobFilter = (props: any) => (
  <AmplifyFilter {...props}>
    <TextInput
      source="listProfilesByFullName.fullName"
      label="fullName"
      alwaysOn
      resettable
    />
  </AmplifyFilter>
);
// filters={<ProfileFilter />}

  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,

  filter={{ listTeamMemberRelationshipsByMemberProfileId: {} }}

*/

// TODO import validation (dates and email)
// TODO try https://github.com/MrHertal/react-admin-amplify#filter

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
  <SelectInput label="Status" source="status" choices={jobStatuses} filter="listImportJobsByStatus" />,
  <MaterialDateTimeInput
    label="Begin Date Range"
    source="lastStartDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
  <MaterialDateTimeInput
    label="End Date Range"
    source="lastEndDateTime"
    options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
  />,
];

const ListActions = (props: any) => {
  const {
    className,
    basePath,
    resource,
    filters,
    filterValues,
    displayedFilters,
    showFilter,
  } = props;
  return (
    <TopToolbar className={className}>
      <FilterButton
        resource={resource}
        filters={filters}
        filterValues={filterValues}
        displayedFilters={displayedFilters}
        showFilter={showFilter}
      />
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

export const ImportJobList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);
  const { loaded, permissions } = usePermissions();

  return (
    <CustomList
      {...props} filters={filters}
      actions={<ListActions />}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <DateField source="lastStartDateTime" label="Date" showTime={true} />
        <TextField source="status" sortable={false} />
        <TextField source="profile.fullName" label="Full name" sortable={false} />
        <TextField source="source.key" label="Filename" sortable={false} />
        {(loaded && (permissions.includes("superuser") || permissions.includes("admin"))) && (
          <DeleteWithConfirmButton />
        )}
        <ShowButton />
      </Datagrid>
    </CustomList>
  );
};

const Aside = (props: AsideProps) => { 

  const {
    record,
  } = props;
  
  const { data, loading, error } = useQuery(
    { 
      type: "getList",
      resource: "importJobAuditLogs",
      payload: {
        pagination: { page: 1, perPage: 50 },
        sort: {
          field: "listImportJobAuditLogsByImportJobId",
          order: "ASC"
        },
        filter: {
          listImportJobAuditLogsByImportJobId: {
            importJobId: record?.id,
          },
        },
      },
    }
  );

  return (
    <SimpleShowLayout>
      <Typography variant="h6">Log</Typography>
      <Timeline
        loaded={!loading && !error && data}
        records={data}
        skeleton={<TimelineSkeleton length={50} />}
      >
        <CustomTimelineGroup />
      </Timeline>
    </SimpleShowLayout>
  );
};

export const ImportJobShow = (props: ShowProps) => {
  
  const { record } = useShowController(props);  
  const propsWithAside = {
    ...props,
    ...(
      (record && record.id) &&
      { aside: <Aside {...props} record={record as ImportJob} /> }
    ),
  };
  return (
    <Show
      {... propsWithAside}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" label="ID" fullWidth />
          <TextField source="status" />
          <AmplifyFileField
            source="source"
            download
            storageOptions={{ level: "protected" }}
          />
          <JsonField
            source="result"
            addLabel={true}
            jsonString={true}
            reactJsonOptions={{
              theme: "shapeshifter",
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: false,
            }}
          />
          <ReferenceField
            source="profileId"
            reference="profiles"
            label="User"
            link="show"
          >
            <TextField source="fullName" />
          </ReferenceField>
          <DateField source="lastStartDateTime" showTime={true} />
          <DateField source="lastEndDateTime" showTime={true} />
          <DateField source="createdAt" showTime={true} />
          <DateField source="updatedAt" showTime={true} />
        </Tab>
        <Tab label="Log">
          <ReferenceManyField
            reference="importJobAuditLogs"
            target="importJobId"
            label="Audit Logs"
            perPage={25}
            fullWidth
            filter={{ listImportJobAuditLogsByImportJobId: {} }}
          >
            <Datagrid>
              <DateField source="date" showTime={true} sortable={false} />
              <TextField source="author" label="Author" sortable={false} />
              <TextField source="resource" label="Resource" sortable={false} />
              <TextField source="action" label="Action" sortable={false} />
              <LogPayloadField source="payload" label="Message" sortable={false} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

export const ImportJobCreate = (props: CreateProps) => {

  const meetingsState = useContext(MeetingsStateContext);
  const notify = useNotify();
  const redirect = useRedirect();
  
  const {
    currentProfile,
  } = meetingsState;

  const transform = async (data: any) => {
    return Auth.currentUserCredentials().then(
      async (creds) => {
        const updateData = {
          ...data,
          ...{
            status: JobStatus.PROCESSING,
            identityId: creds.identityId,
          }
        }
        logger.info("updateData", updateData);
        return updateData;
      }
    );
  };

  const onSuccess = async (response: any) => {
    const {
      data,
    } = response;
    logger.info("onSuccess.data", data);
    const successMessage = "Import job is processing...";
    notify(successMessage, { type: "success" });
    redirect(`/importJobAuditLogs?displayedFilters=%7B%22importJobId%22%3Atrue%7D&filter=%7B%22importJobId%22%3A%22${data.id}%22%7D&order=ASC&page=1&perPage=10&sort=id`); // TODO use default filter format
  };

  return (
    <Create
      {...props}
      transform={transform}
      onSuccess={onSuccess}
    >
      <SimpleForm>
        <AmplifyFileInput
          source="source"
          multiple={false}
          isRequired
          storageOptions={{ level: "protected" }}
          validate={validateRequired}
        />
        <TextInput
          source="profileId"
          type="hidden"
          defaultValue={currentProfile?.id}
          value={currentProfile?.id}
          label={false}
          disabled
        />
      </SimpleForm>
    </Create>
  );
};
