import React, { useCallback, useState } from "react";
import {
  Datagrid,
  DateField,
  Link,
  ReferenceField,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  required,
  ListButton,
  ReferenceInput,
  AutocompleteInput,
  EmailField,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  Edit,
  EditButton,
  useRedirect,
  useNotify,
  useMutation,
  Toolbar,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import { customAlphabet } from "nanoid/async";
import { Logger } from "aws-amplify";
import { DateTimeInput as MaterialDateTimeInput } from "./MaterialDatePicker";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import type { CreateForwardEmailInput, ForwardEmail, UpdateForwardEmailInput } from "../API";

const defaultQuery = "listForwardEmails";
const validateRequired = [required()];
const logger = new Logger("ForwardEmail");

const filters = [
  <ReferenceInput
    source="profileId"
    reference="profiles"
    label="User"
    filterToQuery={(searchText) => ({
      listProfiles: { fullName: searchText },
    })}
    perPage={700}
    resettable
    filter="listForwardEmailsByProfileId"
    >
    <AutocompleteInput optionText="fullName" />
  </ReferenceInput>,
];

export const ForwardEmailList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
    {...props}
    bulkActionButtons={false}
    pagination={<ListPagination />}
    filters={filters}
  >
    <Datagrid>
      <TextField source="profile.fullName" label="User name" />
      <EmailField source="publicEmail" sortable={false} />
      <TextField source="received" sortable={false} />
      <TextField source="remaining" sortable={false} />
      <DateField source="expireAt" sortable={false} showTime={true} />
      <ShowButton />
    </Datagrid>
  </CustomList>
  );
};


export const ForwardEmailShow = (props: ShowProps) => {

  const ShowActions = (props: ShowProps) => {
    const {
      className,
      basePath,
      id,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <ListButton />
      </TopToolbar>
    );
  };

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" fullWidth />
        <EmailField source="publicEmail" fullWidth />
        <EmailField source="privateEmail" fullWidth />
        <TextField source="received" />
        <TextField source="remaining" />
        <DateField source="expireAt" showTime={true} />
        <ReferenceField
          label="User"
          source="profileId"
          reference="profiles"
          fullWidth
        >
          <TextField source="fullName" fullWidth />
        </ReferenceField>
        <ReferenceField
          label="Seller"
          source="sellerProfileId"
          reference="profiles"
          fullWidth
        >
          <TextField source="fullName" fullWidth />
        </ReferenceField>
        <DateField source="createdAt" showTime={true} />
        <DateField source="updatedAt" showTime={true} />
      </SimpleShowLayout>
    </Show>
  );
}

export const ForwardEmailCreate = (props: CreateProps) => {

  const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNPQRSTUVWXYZ", 9);
  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (forwardEmailData: { data: ForwardEmail }) => {
    const {
      data,
    } = forwardEmailData;
    let message = "ForwardEmail created";
    notify(message, { type: "success" });
    redirect(`/forwardEmails/${data.id}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: CreateForwardEmailInput) => {
      logger.log("Received create data", data);
      const validFields = [
        "received",
        "remaining",
        "publicEmail",
        "expireAt",
        "privateEmail",
        "publicReplyEmail",
        "profileId",
        "sellerProfileId",
        "owner",
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from create data`, data);
        }
      }
      const {
        publicEmail,
        received,
        // remaining,
      } = data;
      if (!publicEmail || (publicEmail && publicEmail.startsWith("xxx"))) {
        try {
          data["publicEmail"] = `${(await nanoid()).toLowerCase()}@forward.physician.direct`;
        } catch(err) { }
      }
      if (!received || received < 0) {
        try {
          data["received"] = 0;
        } catch(err) { }
      }
      await mutate(
        {
          type: "create",
          resource: "forwardEmails",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  return (
    <Create
      {...props}
    >
      <SimpleForm
        toolbar={<Toolbar alwaysEnableSaveButton={true} />}
        initialValues={
          {
            publicEmail: 'xxxxxxxxx@forward.physician.direct',
            received: 0,
            remaining: 100,
          }
        }
        save={save}
      >
        <TextInput source="publicEmail" type="email" fullWidth disabled />
        <TextInput source="privateEmail" type="email" fullWidth isRequired />
        <NumberInput source="received" />
        <NumberInput source="remaining" />
        <MaterialDateTimeInput
          source="expireAt"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired={false}
          // validate={validateRequired}
        />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          perPage={700}
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
          source="sellerProfileId"
          reference="profiles"
          label="Assigned Seller"
          perPage={700}
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          isRequired={false}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

export const ForwardEmailEdit = (props: EditProps) => {

  const notify = useNotify();
  const redirect = useRedirect();
  const [mutate] = useMutation();

  const onSuccess = (forwardEmailData: { data: ForwardEmail }) => {
    const {
      data,
    } = forwardEmailData;
    let message = "ForwardEmail updated";
    notify(message, { type: "success" });
    redirect(`/forwardEmails/${data.id}`);
  };
  // See: https://marmelab.com/react-admin/doc/3.19/CreateEdit.html#submission-validation
  const save = useCallback(
    async (data: UpdateForwardEmailInput) => {
      logger.log("Received update data", data);
      const validFields = [
        "id",
        "received",
        "remaining",
        "publicEmail",
        "expireAt",
        "privateEmail",
        "publicReplyEmail",
        "profileId",
        "sellerProfileId",
        "owner",
      ];
      const dataKeys = Object.keys(data);
      for (let i = 0; i < dataKeys.length; i=i+1) {
        const dataKey = dataKeys[i];
        if (!validFields.includes(dataKey)) {
          // @ts-ignore
          delete data[dataKey];
          logger.warn(`^ Invalid field "${dataKey}" removed from update data`, data);
        }
      }
      const {
        publicEmail,
        received,
        // remaining,
      } = data;
      /* if (!publicEmail || (publicEmail && publicEmail.startsWith("xxx"))) {
        try {
          data["publicEmail"] = `${(await nanoid()).toLowerCase()}@forward.physician.direct`;
        } catch(err) { }
      } */
      if (!received || received < 0) {
        try {
          data["received"] = 0;
        } catch(err) { }
      }
      await mutate(
        {
          type: "update",
          resource: "forwardEmails",
          payload: { data },
        },
        {
          returnPromise: true,
          onSuccess
        }
      );
    },
    [mutate],
  );

  const EditActions = (props: EditProps) => {
    const {
      className,
      basePath,
      id,
    } = props;
    const record = useRecordContext();
    if (!record) {
      return <></>;
    }
    return (
      <TopToolbar className={className}>
        <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
        <ShowButton
          component={Link}
          to={{
            pathname: `/profiles/${record.profileId}/show`,
          }}
          label="Return to profile"
        />
        <ListButton />
      </TopToolbar>
    );
  };

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="optimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar />}
        save={save}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="publicEmail" type="email" fullWidth disabled />
        <TextInput source="privateEmail" type="email" fullWidth />
        <NumberInput source="received" />
        <NumberInput source="remaining" />
        <MaterialDateTimeInput
          source="expireAt"
          options={{ format: 'MM/dd/yyyy, hh:mm a', ampm: true, clearable: true }}
          isRequired={false}
          // validate={validateRequired}
        />
        <ReferenceInput
          source="profileId"
          reference="profiles"
          label="User"
          perPage={700}
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          alwaysOn
          isRequired
          validate={validateRequired}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
        <ReferenceInput
          source="sellerProfileId"
          reference="profiles"
          label="Assigned Seller"
          perPage={700}
          filterToQuery={(searchText) => ({
            listProfiles: { fullName: searchText },
          })}
          isRequired={false}
        >
          <AutocompleteInput optionText="fullName" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}
