import React, { useState } from "react";
import {
  Create,
  CreateButton,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  Link,
  ListButton,
  ReferenceManyField,
  RefreshButton,
  Show,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  UrlField,
  required,
} from "react-admin";
import type {
  CreateProps,
  EditProps,
  ListProps,
  ShowProps,
} from "react-admin";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DeleteWithConfirmToolbar from "./DeleteWithConfirmToolbar";
import { CustomList, ListPagination } from "./CustomList";
import TopToolbar from "./TopToolbar";

const defaultQuery = "listOrganizations";
const validateRequired = [required()];

const filters = [
  <TextInput label="Name" source="name" alwaysOn resettable />,
];

const AddNewOrganizationLocationButton = (props: ShowProps | EditProps) => (
  <CreateButton
    component={Link}
    to={{
      pathname: "/organizationLocations/create",
      state: { record: { organizationId: props.id as string } },
      search: `?source=${JSON.stringify({ organizationId: props.id as string })}`,
    }}
    label="Add location"
  >
    <ContactMailIcon />
  </CreateButton>
);

const ShowActions = (props: ShowProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <RefreshButton />
      <AddNewOrganizationLocationButton {...props} />
      <EditButton basePath={basePath} label="Edit" record={{ id: id as string }} />
    </TopToolbar>
  );
};

const EditActions = (props: EditProps) => {
  const {
    className,
    basePath,
    id
  } = props;
  return (
    <TopToolbar className={className}>
      <ListButton basePath={basePath} />
      <AddNewOrganizationLocationButton {...props} />
      <ShowButton basePath={basePath} label="Show" record={{ id: id as string }} />
    </TopToolbar>
  );
};

export const OrganizationList = (props: ListProps) => {
  const [query, setQuery] = useState(defaultQuery);

  return (
    <CustomList
      {...props}
      filters={filters}
      bulkActionButtons={false}
      perPage={300}
      pagination={<ListPagination rowsPerPageOptions={
        [300, 400, 500, 600, 700, 800, 1000]
      } />}
    >
      <Datagrid>
        <TextField source="externalId" label="External ID" sortable={false} />
        <TextField source="name" sortable={false} />
        <UrlField source="url" label='URL (This field not required. Value must begin with "http://" or "https://".)' sortable={false} />
        <TextField source="phoneNumber" sortable={false} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </CustomList>
  );
};

export const OrganizationShow = (props: ShowProps) => {

  return (
    <Show
      {...props}
      actions={<ShowActions {...props} />}
    >
      <SimpleShowLayout>
        <TextField source="id" label="ID" />
        <TextField source="externalId" label="External ID" />
        <TextField source="name" />
        <UrlField source="url" label="URL" />
        <TextField source="description" />
        <TextField source="phoneNumber" />
        <ReferenceManyField
          reference="products"
          target="organizationId"
          perPage={10}
          label="Products"
          fullWidth
          filter={{ listProductsByOrganizationId: {} }}
        >
          <Datagrid>
            <TextField source="name" sortable={false} />
            <TextField source="description" sortable={false} />
            <DateField source="createdAt" showTime={true} sortable={false} />
            <DateField source="updatedAt" showTime={true} sortable={false} />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <DateField showTime={true} source="createdAt" />
        <DateField showTime={true} source="updatedAt" />
      </SimpleShowLayout>
    </Show>
  );
}

export const OrganizationEdit = (props: EditProps) => {

  return (
    <Edit
      {...props}
      actions={<EditActions {...props} />}
      undoable={false}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={<DeleteWithConfirmToolbar />}
      >
        <TextInput source="id" label="ID" disabled />
        <TextInput source="externalId" label="External ID" />
        <TextInput
          source="name"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="description"
          fullWidth
          multiline={true}
          rows={4}
        />
        <TextInput source="url" label='URL (This field not required. Value must begin with "http://" or "https://".)'
 fullWidth />
        <TextInput source="phoneNumber" />
      </SimpleForm>
    </Edit>
  );
}

export const OrganizationCreate = (props: CreateProps) => {

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="externalId" label="External ID" />
        <TextInput
          source="name"
          isRequired
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="description"
          fullWidth
          multiline={true}
          rows={4}
        />
        <TextInput source="url" label='URL (This field not required. Value must begin with "http://" or "https://".)'
 fullWidth />
        <TextInput source="phoneNumber" />
      </SimpleForm>
    </Create>
  );
}


